<template>
    <ValidationObserver ref="validator" slim>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-toolbar flat>
                <v-toolbar-title>{{ $t('src\\views\\pagetemplates\\edit.3725') }}</v-toolbar-title>
            </v-toolbar>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                    v-model="entity.title"
                    :label="$t('src\\views\\pagetemplates\\edit.8687')"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                    v-model="entity.code"
                    :label="$t('src\\views\\pagetemplates\\edit.9319')"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
            

            <v-row class="mb-5">
                <v-col>
                    <h4>{{ $t('src\\views\\pagetemplates\\edit.7009') }}</h4>
                    <template v-if="entity.code === 'main'">
                        <div class="pageTemplates__content__block">
                            <p>{{ $t('src\\views\\pagetemplates\\edit.4398') }}</p>
                            <v-switch
                                v-model="entity.blocks.main.show"
                                :label="$t('src\\views\\pagetemplates\\edit.1076')"
                            />
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.main.title.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.760')"
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.main.title.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.156')"
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.main.preview.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.8100')"
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.main.preview.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.7960')"
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-img :src="imageSrc(entity.blocks.main.picture)" />
                            <v-file-input
                                :label="$t('src\\views\\pagetemplates\\edit.1088')"
                                outlined
                                prepend-icon="mdi-image"
                                @change="setPicture('main', $event)"
                            />
                            <p>{{ $t('src\\views\\pagetemplates\\edit.816') }}</p>
                            <template v-for="(link, index) in entity.blocks.main.links">
                                <v-row :key="`main-links-${index}`">
                                    <v-col cols="3">
                                        <v-text-field
                                            v-model="link.title.ru"
                                            :label="$t('src\\views\\pagetemplates\\edit.80')"
                                            hide-details
                                            outlined
                                        />
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field
                                            v-model="link.title.en"
                                            :label="$t('src\\views\\pagetemplates\\edit.587')"
                                            hide-details
                                            outlined
                                        />
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field
                                            v-model="link.link"
                                            :label="$t('src\\views\\pagetemplates\\edit.1441')"
                                            hide-details
                                            outlined
                                        />
                                    </v-col>
                                    <v-col cols="2">
                                        <div class="d-flex justify-end">
                                            <v-btn
                                                class="mt-2"
                                                color="error"
                                                @click="removeMainBlockLink(index)">{{ $t('src\\views\\pagetemplates\\edit.2872') }}</v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                            </template>
                            <v-row>
                                <v-col>
                                    <v-btn
                                        color="primary"
                                        @click="addMainBlockLink">{{ $t('src\\views\\pagetemplates\\edit.300') }}</v-btn>
                                </v-col>
                            </v-row>
                        </div>
                        <div class="pageTemplates__content__block">
                            <p>{{ $t('src\\views\\pagetemplates\\edit.202') }}</p>
                            <v-switch
                                v-model="entity.blocks.trips.show"
                                :label="$t('src\\views\\pagetemplates\\edit.1076')"
                            />
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.trips.title.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.760')"
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.trips.title.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.156')"
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                        </div>
                        <div class="pageTemplates__content__block">
                            <p>{{ $t('src\\views\\pagetemplates\\edit.4398') }}</p>
                            <v-switch
                                v-model="entity.blocks.banner.show"
                                :label="$t('src\\views\\pagetemplates\\edit.1076')"
                            />
                            <v-text-field
                                v-model="entity.blocks.banner.link"
                                :label="$t('src\\views\\pagetemplates\\edit.9636')"
                                outlined
                            />
                            <v-img :src="imageSrc(entity.blocks.banner.picture)" />
                            <v-file-input
                                :label="$t('src\\views\\pagetemplates\\edit.1088')"
                                outlined
                                prepend-icon="mdi-image"
                                @change="setPicture('banner', $event)"
                            />
                        </div>
                        <div class="pageTemplates__content__block">
                            <p>{{ $t('src\\views\\pagetemplates\\edit.8488') }}</p>
                            <v-switch
                                v-model="entity.blocks.offer.show"
                                :label="$t('src\\views\\pagetemplates\\edit.1076')"
                            />
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.offer.title.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.760')"
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.offer.title.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.156')"
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-text-field
                                v-model="entity.blocks.offer.link"
                                :label="$t('src\\views\\pagetemplates\\edit.5022')"
                                outlined
                            />
                            <v-img :src="imageSrc(entity.blocks.offer.picture)" />
                            <v-file-input
                                :label="$t('src\\views\\pagetemplates\\edit.1088')"
                                outlined
                                prepend-icon="mdi-image"
                                @change="setPicture('offer', $event)"
                            />
                            <v-row>
                                <v-col cols="6">
                                    <tiptap-vuetify
                                        v-model="entity.blocks.offer.text.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.6986')"
                                        :extensions="extensions"
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <tiptap-vuetify
                                        v-model="entity.blocks.offer.text.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.6893')"
                                        :extensions="extensions"
                                    />
                                </v-col>
                            </v-row>
                        </div>
                        <div class="pageTemplates__content__block">
                            <p>{{ $t('src\\views\\pagetemplates\\edit.2068') }}</p>
                            <v-switch
                                v-model="entity.blocks.tripTypes.show"
                                :label="$t('src\\views\\pagetemplates\\edit.1076')"
                            />
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.tripTypes.title.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.760')"
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.tripTypes.title.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.156')"
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <p>{{ $t('src\\views\\pagetemplates\\edit.940') }}</p>
                            <v-row>
                                <template v-for="tripType in tripTypes">
                                    <v-col :key="tripType.code" cols="2">
                                        <v-switch
                                            v-model="entity.blocks.tripTypes.types[tripType.code]"
                                            :label="tripType.title"
                                        />
                                    </v-col>
                                </template>
                            </v-row>
                        </div>
                        <div class="pageTemplates__content__block">
                            <p>{{ $t('src\\views\\pagetemplates\\edit.2837') }}</p>
                            <v-switch
                                v-model="entity.blocks.news.show"
                                :label="$t('src\\views\\pagetemplates\\edit.1076')"
                            />
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.news.title.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.760')"
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.news.title.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.156')"
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                        </div>
                        <div class="pageTemplates__content__block">
                            <p>{{ $t('src\\views\\pagetemplates\\edit.530') }}</p>
                            <v-switch
                                v-model="entity.blocks.organizers.show"
                                :label="$t('src\\views\\pagetemplates\\edit.1076')"
                            />
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.organizers.title.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.760')"
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.organizers.title.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.156')"
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                        </div>
                        <div class="pageTemplates__content__block">
                            <p>{{ $t('src\\views\\pagetemplates\\edit.8706') }}</p>
                            <v-switch
                                v-model="entity.blocks.reviews.show"
                                :label="$t('src\\views\\pagetemplates\\edit.1076')"
                            />
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.reviews.title.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.760')"
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.reviews.title.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.156')"
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                        </div>
                        <div class="pageTemplates__content__block">
                            <p>{{ $t('src\\views\\pagetemplates\\edit.3153') }}</p>
                            <v-switch
                                v-model="entity.blocks.photos.show"
                                :label="$t('src\\views\\pagetemplates\\edit.1076')"
                            />
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.photos.title.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.760')"
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.photos.title.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.156')"
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <template v-for="(photo, index) in entity.blocks.photos.pictures">
                                    <template v-if="photo">
                                        <v-col :key="`photos-${index}`" align="center">
                                            <v-img :src="imageSrc(photo)" class="my-0" />
                                            <v-btn
                                                class="mb-2"
                                                color="error"
                                                @click="removePhoto('photos', index)">{{ $t('src\\views\\pagetemplates\\edit.2872') }}</v-btn>
                                        </v-col>
                                    </template>
                                </template>
                            </v-row>
                            <v-file-input
                                :label="$t('src\\views\\pagetemplates\\edit.1088')"
                                outlined
                                prepend-icon="mdi-image"
                                @change="setPhoto('photos', $event)"
                            />
                            <v-text-field
                                v-model="entity.blocks.photos.link"
                                :label="$t('src\\views\\pagetemplates\\edit.9547')"
                                outlined
                            />
                        </div>
                        <div class="pageTemplates__content__block">
                            <p>{{ $t('src\\views\\pagetemplates\\edit.9354') }}</p>
                            <v-switch
                                v-model="entity.blocks.feedback.show"
                                :label="$t('src\\views\\pagetemplates\\edit.1076')"
                            />
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.feedback.title.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.760')"
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.feedback.title.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.156')"
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.feedback.preview.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.760')"
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.feedback.preview.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.156')"
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-img :src="imageSrc(entity.blocks.feedback.picture)" />
                            <v-file-input
                                :label="$t('src\\views\\pagetemplates\\edit.1088')"
                                outlined
                                prepend-icon="mdi-image"
                                @change="setPicture('feedbacks', $event)"
                            />
                        </div>
                    </template>
                    <template v-if="entity.code === 'footer'">
                        <template v-for="(col, сindex) in ['col1', 'col2', 'col3']">
                            <div :key="`cols-${сindex}`" class="pageTemplates__content__block">
                                <p>{{ $t('src\\views\\pagetemplates\\edit.2292', { '1': сindex + 1 }) }}</p>
                                <v-switch
                                    v-model="entity.blocks[col].show"
                                    :label="$t('src\\views\\pagetemplates\\edit.1076')"
                                />
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field
                                            v-model="entity.blocks[col].ru"
                                            :label="$t('src\\views\\pagetemplates\\edit.80')"
                                            outlined
                                        />  
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            v-model="entity.blocks[col].en"
                                            :label="$t('src\\views\\pagetemplates\\edit.587')"
                                            outlined
                                        />
                                    </v-col>
                                </v-row>
                                <p>{{ $t('src\\views\\pagetemplates\\edit.816') }}</p>
                                <template v-for="(link, index) in entity.blocks[col].links">
                                    <v-row :key="`${col}-links-${index}`">
                                        <v-col cols="3">
                                            <v-text-field
                                                v-model="link.ru"
                                                :label="$t('src\\views\\pagetemplates\\edit.80')"
                                                hide-details
                                                outlined
                                            />
                                        </v-col>
                                        <v-col cols="3">
                                            <v-text-field
                                                v-model="link.en"
                                                :label="$t('src\\views\\pagetemplates\\edit.587')"
                                                hide-details
                                                outlined
                                            />
                                        </v-col>
                                        <v-col cols="3">
                                            <v-text-field
                                                v-model="link.link"
                                                :label="$t('src\\views\\pagetemplates\\edit.6111')"
                                                hide-details
                                                outlined
                                            />
                                        </v-col>
                                        <v-col cols="2">
                                            <div class="d-flex justify-end">
                                                <v-btn
                                                    class="mt-2"
                                                    color="error"
                                                    @click="removeFooterBlockLink(col, index)">{{ $t('src\\views\\pagetemplates\\edit.2872') }}</v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </template>
                                <v-row>
                                    <v-col>
                                        <v-btn
                                            color="primary"
                                            @click="addFooterBlockLink(col)">{{ $t('src\\views\\pagetemplates\\edit.300') }}</v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </template>
                        <div class="pageTemplates__content__block">
                            <p>{{ $t('src\\views\\pagetemplates\\edit.9869') }}</p>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contacts.title.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.760')"
                                        hide-details
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contacts.title.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.156')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contacts.phoneTitle.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.80')"
                                        hide-details
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contacts.phoneTitle.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.587')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contacts.phone.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.2017')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contacts.phone.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.5258')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contacts.emailTitle.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.80')"
                                        hide-details
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contacts.emailTitle.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.587')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contacts.email.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.email')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contacts.email.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.email')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contacts.fb.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.fb')"
                                        hide-details
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contacts.fb.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.fb')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contacts.vk.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.vk')"
                                        hide-details
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contacts.vk.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.vk')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contacts.telegram.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.telegram')"
                                        hide-details
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contacts.telegram.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.telegram')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contacts.instagram.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.instagram')"
                                        hide-details
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contacts.instagram.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.instagram')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                        </div>
                        <div class="pageTemplates__content__block">
                            <p>{{ $t('src\\views\\pagetemplates\\edit.2629') }}</p>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.technical.title.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.2332')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.technical.title.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.3700')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.technical.PrivacyPolicy.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.8524')"
                                        hide-details
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.technical.PrivacyPolicy.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.1856')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.technical.BookingTerms.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.3103')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.technical.BookingTerms.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.7376')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                        </div>
                    </template>
                    <template v-if="entity.code === 'headerMenu'">
                        <template v-for="(menu, mindex) in ['mainMenuAbout', 'mainMenuBlogger', 'mainMenuOrganizer', 'mainMenuTrips', 'bloggerMenu', 'organizerMenu', 'hiddenMenu']">
                            <div :key="`menu-cols-${mindex}`" class="pageTemplates__content__block">
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field
                                            v-model="entity.blocks[menu].ru"
                                            :label="$t('src\\views\\pagetemplates\\edit.80')"
                                            outlined
                                        />  
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            v-model="entity.blocks[menu].en"
                                            :label="$t('src\\views\\pagetemplates\\edit.587')"
                                            outlined
                                        />
                                    </v-col>
                                </v-row>
                                <p>{{ $t('src\\views\\pagetemplates\\edit.816') }}</p>
                                <template v-for="(link, index) in entity.blocks[menu].links">
                                    <v-row :key="`mainMenuAbout-links-${index}`">
                                        <v-col cols="3">
                                            <v-text-field
                                                v-model="link.ru"
                                                :label="$t('src\\views\\pagetemplates\\edit.80')"
                                                hide-details
                                                outlined
                                            />
                                        </v-col>
                                        <v-col cols="3">
                                            <v-text-field
                                                v-model="link.en"
                                                :label="$t('src\\views\\pagetemplates\\edit.587')"
                                                hide-details
                                                outlined
                                            />
                                        </v-col>
                                        <v-col cols="3">
                                            <v-text-field
                                                v-model="link.link"
                                                :label="$t('src\\views\\pagetemplates\\edit.6756')"
                                                hide-details
                                                outlined
                                            />
                                        </v-col>
                                        <v-col cols="2">
                                            <div class="d-flex justify-end">
                                                <v-btn
                                                    class="mt-2"
                                                    color="error"
                                                    @click="removeMenuBlockLink(menu, index)">{{ $t('src\\views\\pagetemplates\\edit.2872') }}</v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </template>
                                <v-row>
                                    <v-col>
                                        <v-btn
                                            color="primary"
                                            @click="addMenuBlockLink(menu)">{{ $t('src\\views\\pagetemplates\\edit.300') }}</v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </template>
                    </template>
                    <template v-if="['questions', 'questionsOperator'].indexOf(entity.code) >= 0">
                        <template v-for="(item, index) in entity.blocks.questions">
                            <div :key="`mainMenuAbout-links-${index}`" class="pageTemplates__content__block">
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field
                                            v-model="item.question.ru"
                                            :label="$t('src\\views\\pagetemplates\\edit.5173')"
                                            hide-details
                                            outlined
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            v-model="item.question.en"
                                            :label="$t('src\\views\\pagetemplates\\edit.6212')"
                                            hide-details
                                            outlined
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-textarea
                                            v-model="item.answer.ru"
                                            :label="$t('src\\views\\pagetemplates\\edit.2032')"
                                            hide-details
                                            outlined
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-textarea
                                            v-model="item.answer.en"
                                            :label="$t('src\\views\\pagetemplates\\edit.8916')"
                                            hide-details
                                            outlined
                                        />
                                    </v-col>
                                    <v-col cols="12">
                                        <div class="d-flex justify-end">
                                            <v-btn
                                                class="mt-2"
                                                color="error"
                                                @click="removeQuestion(index)">{{ $t('src\\views\\pagetemplates\\edit.2872') }}</v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </template>
                        <v-row>
                            <v-col>
                                <v-btn
                                    color="primary"
                                    @click="addQuestion()">{{ $t('src\\views\\pagetemplates\\edit.9181') }}</v-btn>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-if="entity.code === 'contacts'">
                        <div class="pageTemplates__content__block">
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.pageTitle.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.7852')"
                                        hide-details
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.pageTitle.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.4209')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.pageSubtitle.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.6519')"
                                        hide-details
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.pageSubtitle.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.4715')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.formTitle.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.5002')"
                                        hide-details
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.formTitle.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.398')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.addressTitle.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.760')"
                                        hide-details
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.addressTitle.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.156')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.address.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.3615')"
                                        hide-details
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.address.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.4394')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.phoneTitle.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.760')"
                                        hide-details
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.phoneTitle.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.156')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.phone.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.7237')"
                                        hide-details
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.phone.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.9581')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.emailTitle.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.760')"
                                        hide-details
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.emailTitle.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.156')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.email.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.email')"
                                        hide-details
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.email.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.email')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.workTitle.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.760')"
                                        hide-details
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.workTitle.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.156')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.work.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.6838')"
                                        hide-details
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.work.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.2194')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.requisitesTitle.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.760')"
                                        hide-details
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.requisitesTitle.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.156')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-textarea
                                        v-model="entity.blocks.contactsFull.requisites.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.3803')"
                                        hide-details
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-textarea
                                        v-model="entity.blocks.contactsFull.requisites.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.3027')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.socialTitle.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.760')"
                                        hide-details
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.socialTitle.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.156')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.fb.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.fb')"
                                        hide-details
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.fb.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.fb')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.vk.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.vk')"
                                        hide-details
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.vk.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.vk')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.telegram.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.telegram')"
                                        hide-details
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.telegram.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.telegram')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.instagram.ru"
                                        :label="$t('src\\views\\pagetemplates\\edit.instagram')"
                                        hide-details
                                        outlined
                                    />  
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="entity.blocks.contactsFull.instagram.en"
                                        :label="$t('src\\views\\pagetemplates\\edit.instagram')"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                        </div>
                    </template>
                </v-col>
            </v-row>


            <v-row
                align="center"
                justify="space-between"
                class="mx-0">
                <v-btn
                    :disabled="!valid"
                    color="primary"
                    @click="save">{{ $t('src\\views\\pagetemplates\\edit.4315') }}</v-btn>
                <v-btn
                    v-if="entity._id"
                    color="error"
                    @click="remove">{{ $t('src\\views\\pagetemplates\\edit.2872') }}</v-btn>
            </v-row>
        </v-form>
    </ValidationObserver>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Image, Code, CodeBlock, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
    import { imageSrc } from '../../helpers';

    export default {
        name: 'NewsEdit',
        components: {
            ValidationProvider,
            ValidationObserver,
            TiptapVuetify,
        },
        data: () => ({
            imageSrc,
            valid: true,
            types: [
                { label: 'Баннер' }
            ],
            extensions: [
                History,
                [Heading, {
                    options: {
                        levels: [2, 3, 4]
                    }
                }],
                Bold,
                Underline,
                Strike,
                Italic,
                ListItem,
                BulletList,
                OrderedList,
                HorizontalRule,
                Link,
            ],
        }),
        computed: {
            ...mapState('pageTemplates', ['entity']),
            ...mapState('dictionaries', {
                tripTypes: state => state.entity
            }),
        },
        async mounted() {
            if(this.$route.params.id) {
                await store.dispatch('pageTemplates/get', { id: this.$route.params.id });
            } else {
                await store.commit('pageTemplates/CLEAR_ENTITY');
            }
            await store.dispatch('dictionaries/getByCode', { code: 'types' });
        },
        methods: {
            async setPicture(block, file) {
                this.entity.blocks[block].picture = await store.dispatch('files/upload', { file, maxSize: 1500 });
            },
            async setPhoto(block, file) {
                const photo = await store.dispatch('files/upload', { file });
                this.entity.blocks[block].pictures.push(photo);
            },
            async removePhoto(block, index) {
                this.entity.blocks[block].pictures.splice(index, 1);
            },
            addMainBlockLink() {
                if(!this.entity.blocks.main.links) this.entity.blocks.main.links = [];
                this.entity.blocks.main.links.push({ ru: '', en: '', link: '' });
            },
            removeMainBlockLink(index) {
                this.entity.blocks.main.links.splice(index, 1);
            },
            addFooterBlockLink(col) {
                this.entity.blocks[col].links.push({ ru: '', en: '', link: '' });
            },
            removeFooterBlockLink(col, index) {
                this.entity.blocks[col].links.splice(index, 1);
            },
            addMenuBlockLink(menu) {
                this.entity.blocks[menu].links.push({ ru: '', en: '', link: '' });
            },
            removeMenuBlockLink(menu, index) {
                this.entity.blocks[menu].links.splice(index, 1);
            },
            addQuestion() {
                console.log(this.entity.blocks.questions);
                this.entity.blocks.questions.push({ question: { ru: '', en: '' }, answer: { ru: '', en: '' } });
            },
            removeQuestion(index) {
                this.entity.blocks.questions.splice(index, 1);
            },
            async save() {
                this.valid = await this.$refs.validator.validate();
                if(this.valid){
                    await store.commit('pageTemplates/SET_ENTITY', this.entity);
                    const response = await store.dispatch('pageTemplates/save');
                    if(response.error) {
                        alert(response.error);
                    } else {
                        await this.$router.push({ name: 'page-templates-list' });
                    }
                }
            },
            async remove() {
                await store.dispatch('pageTemplates/delete', { id: this.entity._id });
                await this.$router.push({ name: 'page-templates-list' });
            }
        }
    }
</script>

<style lang="scss">
    .v-image {
        width: 300px;
        margin: 0 auto 20px;
        border-radius: 6px;
    }
    .pageTemplates__content__block {
        margin: 10px 0;
        padding: 20px;
        border: 1px solid #cecece;
        border-radius: 4px;
    }
</style>
